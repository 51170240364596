import { useState } from "react";
import { useForm } from "react-hook-form";

const landingpage = () => {
  const pattern1 = require("../images/card-pattern1.png");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [CustomerName, setCustomerName] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [CustomerEmail, setCustomerEmail] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [CustomerPhone, setCustomerPhone] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [message, setMessage] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    register,
    handleSubmit,
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm();
  let onSubmit = async (e) => {
    try {
      let res = await fetch("https://api.putra-jaya.com/Customers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          CustomerName: e.CustomerName,
          CustomerEmail: e.CustomerEmail,
          CustomerPhone: e.CustomerPhone,
        }),
      });
      let resJson = await res.json();
      if (res.status === 201) {
        setCustomerName("");
        setCustomerEmail("");
        setCustomerPhone("");
        setMessage("Registrasi berhasil");
      } else {
        setMessage("Registrasi Gagal!");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="columns is-centered">
        <div className="column mt-5 customImage is-half">
          <figure>
            <img
              className="has-image-centered"
              src="https://www.putra-jaya.com/wp-content/uploads/2022/11/cropped-cropped-cropped-cropped-LOGO-PUJA-1-e1667634377242.png"
              alt="Putra Jaya"
            />
          </figure>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column mt-5 customImage">
          <figure>
            <img src={pattern1} alt="Placeholder image" />
          </figure>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column mx-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns">
          <div className="column is-6 mx-5">
            <input
              className="input"
              type="text"
              value={CustomerName}
              placeholder="Nama"
              {...register("CustomerName", { required: true })}
              onChange={(e) => setCustomerName(e.target.value)}
            ></input>
            {errors.CustomerName && (
              <p className="validationText">*Nama tidak boleh kosong</p>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column is-6 mx-5">
            <input
              className="input"
              type="text"
              value={CustomerEmail}
              placeholder="Email"
              {...register("CustomerEmail", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              onChange={(e) => setCustomerEmail(e.target.value)}
            ></input>
            {errors.CustomerEmail && (
              <p className="validationText">
                *Email tidak boleh kosong / harus diisi dengan format email yang
                benar
              </p>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column is-6 mx-5">
            <input
              className="input"
              type="text"
              value={CustomerPhone}
              placeholder="Nomor Telpon"
              {...register("CustomerPhone", { required: true })}
              onChange={(e) => setCustomerPhone(e.target.value)}
            ></input>
            {errors.CustomerName && (
              <p className="validationText">
                *Nomor Telepon tidak boleh kosong
              </p>
            )}
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column mx-5">
            <button class="button is-success">Submit</button>
          </div>
        </div>
        <div className="column is-centered">
          <div className="column mx-5">
            <div className="messageSuccess">{message ? <p>{message}</p> : null}</div>
          </div>
        </div>
      </form>
    </>
  );
};

export default landingpage;
