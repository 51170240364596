

import './App.css';
import Landingpage from './component/landingpage';

function App() {
  return (
    <div className="container">
        <Landingpage/>
      </div>
  
  );
}

export default App;
